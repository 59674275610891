import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import { useUserContext } from "../../../context/UserContext";
import { signInAnonymousUser } from "../utils/authHelpers";
import { ROUTES } from "../../../config/routes";
import { useLocation } from "react-router-dom";
import { Button } from "../../../components";
import { INTRO_PAGE_CONTENT } from "../../../constants/auth-pages.constants";
import { SerializedStyles } from "@emotion/react";

interface IGuestUserBtnProps {
  extraCss?: SerializedStyles;
  disabled?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const GuestUserBtn = (props: IGuestUserBtnProps) => {
  const navigate = useNavigateState();
  const { user, setUser } = useUserContext();
  const location = useLocation();

  const handleGetSTartedBtnClick = async () => {
    props.setIsLoading && props.setIsLoading(true);
    if (!user) {
      const anonymsUser = await signInAnonymousUser();
      setUser(anonymsUser);
    }

    navigate(ROUTES.TEAM_ID_PAGE, {
      state: location.state,
    });
    props.setIsLoading && props.setIsLoading(false);
  };

  return (
    <Button
      button="outline-gradient"
      disabled={props.disabled}
      onClick={handleGetSTartedBtnClick}
      extraCss={props.extraCss}
    >
      {INTRO_PAGE_CONTENT.VISITOR_MODE_BTN}
    </Button>
  );
};
