import ReactGA4 from "react-ga4";
import { ENV_OPTIONS } from "../config/app.config";
interface IGa4Events {
  category: string;
  action: string;
  label?: string;
}

export const GA4_EVENTS = {
  LARGE_LEAGUE_SELECTED: {
    category: "Global",
    action: "Large League Selected",
  },
  FAV_LEAGUE_PICKED: {
    category: "Global",
    action: '"Fav League" picked',
  },
  SOMETHING_COOL_CLICKED: {
    category: "Home Page",
    action: '"Show Something Cool" Click',
  },
  PREV_CAPTAINS_CLICKED: {
    category: "League Page",
    action: '"Show Prev Captains" Click',
  },
  TRANSFERS_MORE_INFO_CLICKED: {
    category: "Team Page",
    action: '"Transfers More Info" Click',
  },
  POINTS_MORE_INFO_CLICKED: {
    category: "Team Page",
    action: '"Points More Info" Click',
  },
  RANK_MORE_INFO_CLICKED: {
    category: "Team Page",
    action: '"Rank More Info" Click',
  },
  HOME_HIGHLIGHT_CARD_CLICKED: {
    category: "Home Page",
    action: '"Home Highlight Card" Click',
  },
  SIGN_UP_GOOGLE_CLICKED: {
    category: "Auth Pages",
    action: '"Sign up google" Click',
  },
  USER_TRIED_DEMO: {
    category: "Landing Page",
    action: "User Tried Demo",
  },
  LANDING_PAGE_GET_STARTED_CLICKED: {
    category: "Landing Page",
    action: '"Get Started" Click',
  },
  TEAM_ID_SUBMITTED_BY_TEAM_NAME: {
    category: "Auth Pages",
    action: "Fpl Team found by Team Name",
  },
  TEAM_ID_SUBMITTED_BY_TEAM_ID: {
    category: "Auth Pages",
    action: "Fpl Team found by Team Id",
  },
  SHARE_HIGHLIGHTS_PAGE: (highlightId: string) => ({
    category: "Share Highlights Page",
    action: `${highlightId} shared`,
  }),
} as const;

export const InitializeGoogleAnalytics = () => {
  if (import.meta.env.VITE_ENVIRONMENT !== ENV_OPTIONS.PROD) return;

  // Initialize GA4 on Prod
  ReactGA4.initialize([
    { trackingId: import.meta.env.VITE_GOOGLE_ANALYTICS_KEY },
    { trackingId: import.meta.env.VITE_GOOGLE_ADS_KEY },
  ]);
};

export const TrackGoogleAnalyticsEvent = ({
  category,
  action,
  label,
}: IGa4Events) => {
  if (import.meta.env.VITE_ENVIRONMENT !== ENV_OPTIONS.PROD) return;

  // Send GA4 Event
  ReactGA4.event({
    category: category,
    action: action,
    label: label || window.location.pathname,
  });
};
