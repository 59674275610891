import password from "../../../assets/icons/password.svg";
import userSvg from "../../../assets/icons/user.svg";
import message from "../../../assets/icons/message.svg";
import {
  Button,
  Checkbox,
  ErrorMessage,
  Input,
  Link,
} from "../../../components";
import { Form, Formik } from "formik";
import { RegisterFormType, RegisterYupSchema } from "../../../utils/validation";
import { REGISTER_PAGE_CONTENT } from "../../../constants/auth-pages.constants";
import { ROUTES } from "../../../config/routes";
import { handleErrors } from "../../../utils/handleErrors";
import { registerSupabase } from "../utils/authHelpers";
import { root } from "../styles";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { SNACKBAR_MESSAGES } from "../../../constants/global.constants";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../../context/UserContext";
import { GuestUserBtn } from "../components";
import { css } from "@emotion/react";

/** Handle the register form */
export const RegisterForm = () => {
  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    policy: false,
    generalErr: "",
  };

  const navigate = useNavigateState();
  const location = useLocation();
  const { setSnackBarState } = useSnackbarContext();
  const { user, signOutUser } = useUserContext();

  const submitForm = async (
    values: RegisterFormType,
    { setSubmitting, setErrors }: any
  ) => {
    try {
      setSubmitting(true);
      // sign out anonymous user
      if (user && user.isAnonymous) {
        await signOutUser();
      }
      await registerSupabase(values, user?.isAnonymous);
      setSnackBarState((prev) => ({
        ...prev,
        isOpen: true,
        message: SNACKBAR_MESSAGES.CONFIRMATION_EMAIL_SENT,
      }));
      navigate(ROUTES.SIGN_IN_PAGE, {
        state: location.state,
      });
    } catch (e) {
      const err = handleErrors(e);
      setErrors({ generalErr: err.message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegisterYupSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting }) => (
        <Form css={root(isSubmitting)}>
          <Input
            Icon={<img src={userSvg} />}
            placeholder={REGISTER_PAGE_CONTENT.NAME_FIELD_PLACEHOLDER}
            id="name"
            name="name"
          />
          <Input
            Icon={<img src={message} />}
            placeholder={REGISTER_PAGE_CONTENT.EMAIL_FIELD_PLACEHOLDER}
            id="email"
            name="email"
          />
          <Input
            Icon={<img src={password} />}
            placeholder={REGISTER_PAGE_CONTENT.PASSWORD_FIELD_PLACEHOLDER}
            type="password"
            id="password"
            name="password"
          />

          <Input
            Icon={<img src={password} />}
            placeholder={REGISTER_PAGE_CONTENT.CONFIRM_PASSWORD_PLACEHOLDER}
            type="password"
            id="confirm_password"
            name="confirm_password"
          />

          <Checkbox name="policy">
            I agree to The{" "}
            <Link to={ROUTES.TERMS_OF_SERVES_PAGE}>Terms of Service</Link> and{" "}
            <Link to={ROUTES.PRIVACY_POLICY_PAGE}>Privacy Policy</Link>
          </Checkbox>

          <ErrorMessage name="generalErr" />
          <Button button="CTA" type="submit" disabled={isSubmitting}>
            {REGISTER_PAGE_CONTENT.CTA_BUTTON_TEXT}
          </Button>
          <GuestUserBtn extraCss={guestBtnStyles} disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

const guestBtnStyles = css`
  height: 3.75rem;
  font-size: 1rem;
`;
