import { TeamDetailsType } from "../../../utils/validation";
import { useUserContext } from "../../../context/UserContext";
import { ROUTES } from "../../../config/routes";
import { addTeamIdSupabase, validateTeamId } from "../utils/authHelpers";
import { AxiosError } from "axios";
import { handleErrors } from "../../../utils/handleErrors";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { SNACKBAR_MESSAGES } from "../../../constants/global.constants";
import { supabaseUserHasTeamId } from "../../../utils/general.helpers";
import { SESSION_STORAGE } from "../../../config/app.config";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import { useEffect, useState } from "react";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";
import { postTeamIdAnalytics } from "../../../utils/customAnalytics";
import { SearchByTeam } from "./SearchByTeam";
import { InputTeamId } from "./InputTeamId";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../../../utils/google-analytics";

/**
 * A form to get the team id
 * Has an auto complete section to get get team id by team name
 * Has an input team for uer to enter the eam id directly
 */

export const TeamDetailsForm = () => {
  const { user, setUser } = useUserContext();
  const { setLeagues } = useGlobalInfoContext();
  const { setSnackBarState } = useSnackbarContext();
  const navigate = useNavigateState();
  const { getHomePath } = useGetUrl();

  const [inputType, setInputType] = useState<"byName" | "byId">("byName");

  const postManagerTeamId = async (teamID: TeamDetailsType) => {
    if (!user) {
      navigate(ROUTES.REGISTER_PAGE);
      setSnackBarState((prev) => ({
        ...prev,
        message: SNACKBAR_MESSAGES.REGISTER_FIRST_TEAM_DETAILS,
        isOpen: true,
      }));
      return;
    }

    await validateTeamId(teamID);
    const userHasTeamIdInSupabase = await supabaseUserHasTeamId();

    // if user doesn't have team id in supabase
    if (!userHasTeamIdInSupabase && !user.isAnonymous) {
      const newUser = await addTeamIdSupabase(teamID);
      setUser(newUser);
    } else if (user) {
      sessionStorage.setItem(
        SESSION_STORAGE.ANON_USER_TEAM_ID,
        teamID.team_id.toString()
      );
      setUser({
        ...user,
        teamId: Number(teamID.team_id),
        selectedLeagueId: undefined, // reset select league -> defaults to first league in select menu
      });
      setLeagues([]);
    }
  };

  const handleSubmit = async (
    teamID: TeamDetailsType,
    { setErrors, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      if (user) {
        postTeamIdAnalytics({
          email: user.email || "noEmail@guestUser.test",
          teamId: String(teamID.team_id),
          inputType: inputType,
        });
      }

      switch (inputType) {
        case "byName":
          TrackGoogleAnalyticsEvent(GA4_EVENTS.TEAM_ID_SUBMITTED_BY_TEAM_NAME);
          break;
        case "byId":
          TrackGoogleAnalyticsEvent(GA4_EVENTS.TEAM_ID_SUBMITTED_BY_TEAM_ID);
          break;
      }

      await postManagerTeamId(teamID);

      const redirectURL = sessionStorage.getItem(SESSION_STORAGE.URL_HISTORY);
      navigate(redirectURL || getHomePath());

      setSubmitting(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response) {
          setErrors({ team_id: e.response.data.error.details.teamId });
        }
      } else {
        const err = handleErrors(e);
        setErrors({ generalErr: err.message });
      }
    }
  };

  useEffect(() => {
    if (user) {
      postTeamIdAnalytics({ email: user.email || "noEmail@guestUser.test" });
    }
  }, [user]);

  return (
    <>
      {inputType === "byName" ? (
        <SearchByTeam
          onSubmit={handleSubmit}
          onNotFoundClick={() => setInputType("byId")}
          user={user}
        />
      ) : (
        <InputTeamId
          onSubmit={handleSubmit}
          onBackClick={() => setInputType("byName")}
        />
      )}
    </>
  );
};
