import { SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useGlobalInfoContext } from "../context/GlobalInfoContext";
import { GAME_WEEK_DROPDOWN_CONTENT } from "../constants/global.constants";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../config/routes";
import { AppDropDown } from ".";
import { HIGHLIGHTS_PAGE_CONTENT } from "../constants/highlightsPage.constants";
import { useIsSharedURL } from "../utils/hooks/useIsSharedURL";
import { css } from "@emotion/react";
import { secondaryColor } from "../config/theme";
import { useEffect } from "react";

interface ISelectGameWeekDropDown {
  lowestValue?: number;
}

/** A dropdown menu to select the game week that update the URL with the selected gw */
export const SelectGameWeekDropDown = (props: ISelectGameWeekDropDown) => {
  const { liveGameWeek } = useGlobalInfoContext();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!liveGameWeek) return;
    if (!searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)) {
      searchParams.set(PATH_PARAMS.SELECTED_GAME_WEEK, liveGameWeek.toString());
      setSearchParams(searchParams);
    }
  }, [liveGameWeek, searchParams]);

  const handleChange = async (event: SelectChangeEvent) => {
    searchParams.set(PATH_PARAMS.SELECTED_GAME_WEEK, event.target.value);
    setSearchParams(searchParams);
  };

  const arrLen = props.lowestValue
    ? liveGameWeek! - props.lowestValue + 1
    : liveGameWeek!;

  const { isShared } = useIsSharedURL();

  if (isShared) {
    return (
      <Stack css={phasePlaceholderStyle}>
        <Typography variant="h1">Selected Phase:</Typography>
        <Typography>
          Game week {searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)}
        </Typography>
      </Stack>
    );
  }

  return (
    <AppDropDown
      label={HIGHLIGHTS_PAGE_CONTENT.SELECT_GAME_WEEK_LABEL}
      isLoading={Boolean(
        liveGameWeek && searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)
      )}
      onChange={handleChange}
      value={searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!}
      loadingText={GAME_WEEK_DROPDOWN_CONTENT.LOADING_TEXT}
      items={Array.from({ length: arrLen }).map((_, index) => ({
        value: liveGameWeek! - index,
        label: GAME_WEEK_DROPDOWN_CONTENT.DROPDOWN_ITEM_TEXT(
          liveGameWeek! - index,
          liveGameWeek === liveGameWeek! - index
        ),
      }))}
    />
  );
};

const phasePlaceholderStyle = css`
  flex-direction: row;
  gap: 0.5rem;

  h1 {
    font-size: clamp(0.75rem, 3vw, 1rem);
    color: ${secondaryColor};
    line-height: 1;
  }

  P {
    font-size: clamp(0.75rem, 3vw, 1rem);
    line-height: 1;
  }
`;
