import { IconButton, Snackbar, Stack, Switch, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { COOKIES_CONCENT_MODALS_CONTENT } from "../constants/global.constants";
import {
  gradientText,
  mq,
  primaryGradient,
  textWrapBalance,
  transition_all,
} from "../config/styles";
import CookiesIcon from "../assets/icons/cookies-icon.svg";
import { AppModal, Button, Link } from ".";
import EastIcon from "@mui/icons-material/East";
import TuneIcon from "@mui/icons-material/Tune";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useEffect, useState } from "react";
import { mainNavigationHeight } from "../config/theme";
import { useUserContext } from "../context/UserContext";
import { ROUTES } from "../config/routes";

export const CookiesConcentModals = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isConcentModalOpen, setIsConcentModalOpen] = useState(false);
  const { isLoadingScreenOpen } = useUserContext();

  useEffect(() => {
    if (!isLoadingScreenOpen) {
      setIsOpen(true);
    }
  }, [isLoadingScreenOpen]);

  const customizeBtnClickHandler = () => {
    setIsOpen(false);
    setIsConcentModalOpen(true);
  };

  return (
    <div>
      <Snackbar
        open={isOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message={COOKIES_CONCENT_MODALS_CONTENT.SNACKBAR.MESSAGE}
        css={snackbarStyles}
      >
        <Stack css={cookiesSnackbarStyles}>
          <Stack css={contentContainerStyles}>
            <div css={titleContainerStyles}>
              <img src={CookiesIcon} />
              <div>
                <Typography css={subTitleStyles}>
                  {COOKIES_CONCENT_MODALS_CONTENT.SNACKBAR.SUBTITLE}
                </Typography>
                <Typography css={titleStyles}>
                  {COOKIES_CONCENT_MODALS_CONTENT.SNACKBAR.TITLE}
                </Typography>
              </div>
            </div>

            <p css={messageStyles}>
              {COOKIES_CONCENT_MODALS_CONTENT.SNACKBAR.MESSAGE}
            </p>
          </Stack>

          <Stack css={actionBtnsContainer}>
            <Stack flexDirection={"row"}>
              <Button
                css={acceptBtnStyles}
                button="gradient"
                onClick={() => {}}
              >
                {COOKIES_CONCENT_MODALS_CONTENT.SNACKBAR.ACCEPT_ALL_BTN}
                <EastIcon />
              </Button>
              <Button
                css={customizeBtnStyles}
                button="outline"
                onClick={customizeBtnClickHandler}
              >
                {COOKIES_CONCENT_MODALS_CONTENT.SNACKBAR.CUSTOMIZE_BTN}
                <TuneIcon />
              </Button>
            </Stack>

            <Button css={rejectBtnStyles} button="text" onClick={() => {}}>
              {COOKIES_CONCENT_MODALS_CONTENT.SNACKBAR.REJECT_ALL_BTN}
            </Button>

            <IconButton css={closeBtnStyles} onClick={() => setIsOpen(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Snackbar>

      <AppModal
        open={isConcentModalOpen}
        onClose={() => setIsConcentModalOpen(false)}
        showCloseButton
        showGradientBg
      >
        <Stack css={modalRootStyles}>
          <Typography css={modalTitleStyles}>
            {COOKIES_CONCENT_MODALS_CONTENT.CUSTOMIZE_MODAL.TITLE}
          </Typography>
          <Typography css={modalMessageStyles}>
            {COOKIES_CONCENT_MODALS_CONTENT.CUSTOMIZE_MODAL.MESSAGE}{" "}
            <Link to={ROUTES.COOKIES_POLICY_PAGE}>
              {COOKIES_CONCENT_MODALS_CONTENT.CUSTOMIZE_MODAL.COOKIES_LINK_TEXT}
            </Link>
            {"."}
          </Typography>
          <Stack css={modalOptionContainerStyles}>
            {COOKIES_CONCENT_MODALS_CONTENT.CUSTOMIZE_MODAL.COOKIES_OPTIONS.map(
              (option) => (
                <Stack css={modalOptionsStyles} key={option}>
                  <Typography>{option}</Typography>
                  <Switch css={switchStyles} />
                </Stack>
              )
            )}
          </Stack>
        </Stack>
      </AppModal>
    </div>
  );
};

const snackbarStyles = css`
  bottom: calc(${mainNavigationHeight} + 1rem) !important;

  ${mq["sm"]} {
    bottom: 1rem !important;
  }
`;

const cookiesSnackbarStyles = css`
  position: relative;
  background: #2a2b3d;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 2rem);
  max-width: 23rem;
  margin-inline: 1rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 2px solid #fff;

  ${mq["md"]} {
    max-width: 57rem;
    flex-direction: row;
    border: none;
    position: static;
  }
`;

const contentContainerStyles = css`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${mq["md"]} {
    gap: 1rem;
  }
`;

const titleContainerStyles = css`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  img {
    aspect-ratio: 1/1;
    display: block;
    width: clamp(1.8rem, 5vw, 2.5rem);
  }

  ${mq["md"]} {
    gap: 0.62rem;
  }
`;

const subTitleStyles = css`
  display: none;
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 400;
  white-space: nowrap;
  margin: 0;

  ${mq["md"]} {
    display: block;
  }
`;

const titleStyles = css`
  display: none;
  ${gradientText}
  white-space: nowrap;
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0;

  ${mq["md"]} {
    display: block;
  }
`;

const messageStyles = css`
  font-family: Poppins;
  font-size: clamp(0.75rem, 1vw, 0.875rem) !important;
  font-weight: 400;
  margin: 0;
  ${textWrapBalance}
`;

const actionBtnsContainer = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const baseBtnStyles = css`
  height: 2.5rem;
  padding: 0.5625rem 1rem;
  border-radius: 0rem;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 0.675rem;
  font-weight: 400;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;

  white-space: nowrap;

  ${mq["md"]} {
    font-size: 0.875rem;
  }
`;

const acceptBtnStyles = css`
  ${baseBtnStyles}
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  font-weight: 700;

  svg {
    font-size: inherit;
  }
`;

const customizeBtnStyles = css`
  ${baseBtnStyles}

  font-weight: 500;
  background: transparent;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;

  svg {
    font-size: inherit;
  }
`;

const rejectBtnStyles = css`
  ${baseBtnStyles}
  padding: 0.5625rem 0rem;
  border-radius: 0.5rem;
  color: white;
  font-weight: 500;
`;

const closeBtnStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: center center;
  transform: translate(50%, -50%);
  background-color: white;
  cursor: pointer;

  svg {
    ${transition_all}
    color: #2a2b3d;
    font-size: 1.5rem;
  }

  :hover {
    background: #ececec;
  }

  ${mq["md"]} {
    position: static;
    transform: translate(0, 0);
    background: #2a2b3d;
    :hover {
      background: #272838;
    }
    svg {
      color: white;
    }
  }
`;

const modalRootStyles = css`
  padding: 1rem;
  gap: 1rem;
`;

const modalTitleStyles = css`
  color: #fff;
  font-family: Poppins;
  font-size: clamp(1.5rem, 3vw, 2.3rem);
  font-weight: 600;
`;

const modalMessageStyles = css`
  font-size: clamp(0.875rem, 1.5vw, 1.25rem);
  ${textWrapBalance}

  a {
    text-decoration: underline;
    font-weight: 600;
  }
`;

const modalOptionContainerStyles = css`
  gap: 0.75rem;
`;

const modalOptionsStyles = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.75rem;
  padding: 0.5rem;
  background: #181829;
`;

const switchStyles = css`
  --height: 28px;
  --width: calc((var(--height) - 2px) * 1.8);
  --padding: 2px;

  height: var(--height) !important;
  width: var(--width) !important;
  padding: 2px !important;
  border-radius: 9999px !important;
  overflow: hidden !important;
  padding: var(--padding) !important;
  transition-duration: 300ms;

  .MuiButtonBase-root {
    transition-duration: 300ms;
    top: 50%;
    transform: translateY(-50%);
    left: var(--padding);
    height: calc(var(--height) - 2px) !important;
    width: calc(var(--height) - 2px) !important;
    padding: 0;
    .MuiSwitch-thumb {
      background: #fff;
    }
  }

  .MuiSwitch-track {
    transition-duration: 300ms;
    background-color: grey;
    width: 100%;
    height: 100%;
    border-radius: 9999px !important;
  }

  .MuiButtonBase-root.Mui-checked {
    transform: translateY(-50%)
      translateX(calc(var(--width) - var(--height) - var(--padding)));
    color: #fff;

    .MuiSwitch-thumb {
      background: url("data:image/svg+xml,%3csvg%20width='28'%20height='28'%20viewBox='0%200%2028%2028'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20fill-rule='evenodd'%20clip-rule='evenodd'%20d='M14%2028C21.732%2028%2028%2021.732%2028%2014C28%206.26801%2021.732%200%2014%200C6.26801%200%200%206.26801%200%2014C0%2021.732%206.26801%2028%2014%2028Z'%20fill='white'/%3e%3cpath%20d='M20.875%209.625L12.125%2018.3746L7.75%2014'%20stroke='black'%20stroke-width='2'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3c/svg%3e") !important;
      background-size: contain !important;
    }

    & + .MuiSwitch-track {
      ${primaryGradient}
      opacity: 1;
      border: 0;
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.5;
    }
  }
`;
