import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { Button } from "../../../components";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import Crown from "../../../assets/landing-page/crown.svg";
import Arrow from "../../../assets/landing-page/arrow.svg";
import PhoneLg from "../../../assets/landing-page/hero-section-phone.webp";
import PhoneSm from "../../../assets/landing-page/hero-section-phone-sm.webp";
import { bgColor, landingPageGreen } from "../../../config/theme";
import { mq } from "../../../config/styles";
import { CTAButtonWrapper } from "./CTAButton";

const CTAButton = CTAButtonWrapper(Button);

/**
 * A section on the landing page that displays the main title and subtitle,
 *   as well as a call-to-action button and a phone image.
 *
 * @returns {ReactElement} A HeroSection component.
 */
export const HeroSection = () => {
  return (
    <Stack css={heroSectionContainer}>
      <Stack css={textContainerStyles}>
        <Typography css={titleStyles}>
          <span>{LANDING_PAGE_CONTENT.HERO_SECTION_CONTENT.TITLE}</span>
          <span css={yetSimpleStyles}>
            <span>{LANDING_PAGE_CONTENT.HERO_SECTION_CONTENT.YET_SIMPLE}</span>
          </span>
          <img src={Crown} alt="crown" css={crownImgStyles} />
          <img src={Arrow} alt="arrow" css={arrowImgStyles} />
        </Typography>
        <Typography css={subTitleStyles}>
          {LANDING_PAGE_CONTENT.HERO_SECTION_CONTENT.SUBTITLE}
        </Typography>
        <CTAButton
          button="solid"
          color={landingPageGreen}
          css={ctaButtonStyles}
        >
          {LANDING_PAGE_CONTENT.HERO_SECTION_CONTENT.BUTTON}
        </CTAButton>
      </Stack>
      <picture>
        {/** Large screen image  */}
        <source media={mq["sm"].split("@media")[1]} srcSet={PhoneLg} />
        {/** Small screen image  */}
        <source srcSet={PhoneSm} />
        <img src={PhoneSm} alt="phone" css={phoneImgStyles} />
      </picture>
    </Stack>
  );
};

const heroSectionContainer = css`
  height: 100%;
  align-items: center;
`;

const textContainerStyles = css`
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding-inline: 1rem;
  margin-top: 1rem;
  flex: 1;
  padding-top: 7ch;

  ${mq["md"]} {
    padding-top: 10ch;
    margin-top: auto;
  }
`;

const titleStyles = css`
  font-weight: 700;
  font-size: clamp(2rem, 3.3vw, 2.6rem);
  position: relative;
`;

const yetSimpleStyles = css`
  position: absolute;
  top: -3.1ch;
  left: 11.4ch;
  span {
    font-weight: 400;
    font-size: clamp(0.75rem, 2.5vw, 1rem);
  }
`;

const crownImgStyles = css`
  position: absolute;
  top: -0.1ch;
  left: 3.35ch;
  width: 1.2ch;
`;

const arrowImgStyles = css`
  position: absolute;
  top: -0.7ch;
  left: 10.4ch;
  width: 1ch;
`;

const subTitleStyles = css`
  font-weight: 400;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  text-align: center;
  max-width: 40rem;
`;

const ctaButtonStyles = css`
  /* background-color: ${landingPageGreen}; */
  color: ${bgColor};
  white-space: nowrap;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  font-weight: 700;
  width: fit-content;
  padding: 1.5rem 2rem;
`;

const phoneImgStyles = css`
  margin-top: 3rem;
  width: 100vw;
  max-width: 60rem;
  height: 40vh;
  object-fit: contain;
`;
