export const SESSION_STORAGE = {
  LANDING_PAGE_TEAM_ID: "landingPageTeamId",
  LANDING_PAGE_LEAGUE_ID: "landingPageLeagueId",
  ANON_USER_TEAM_ID: "anonUserTeamId",
  IS_DEMO_GA_EVENT_FIRED: "isDemoGaEventFired",
  TEMP_TEAM_ID: "tempTeamId",
  TEMP_SELECTED_LEAGUE_ID: "tempSelectedLeague",
  CURRENT_GAME_WEEK: "currentGameWeek",
  URL_HISTORY: "urlHistory",
  IS_CONVERTED_ANON_USER_BY_OAuth: "isConvertedAnonUserByOAuth",
  ROLLBAR_USER: "rollBarUser",
} as const;

export const ENV_OPTIONS = {
  DEV: "development",
  PROD: "production",
  PREVIEW: "preview",
  LOCAL: "local",
} as const;
