import { AuthApiError, Provider } from "@supabase/supabase-js";
import { supabase } from "../../../config/supabase";
import { ROUTES } from "../../../config/routes";
import {
  RegisterFormType,
  ResetPasswordPasswordFormType,
  SignInFormType,
  TeamDetailsType,
  forgotPasswordFormType,
} from "../../../utils/validation";
import { AUTH_ERROR_MESSAGES } from "../../../constants/auth-pages.constants";
import { FPL_CHAMP_ENDPOINTS } from "../../../config/fplChampEndpoints.constants";
import { formatUser } from "../../../utils/general.helpers";
import { axiosInstance } from "../../../config/axios.config";
import { SESSION_STORAGE } from "../../../config/app.config";
import { IUser } from "../../../context/UserContext";

/** Validate the team id from the backend */
export const validateTeamId = async ({ team_id }: TeamDetailsType) => {
  await axiosInstance.get(FPL_CHAMP_ENDPOINTS.VALIDATE_TEAM_ID(team_id));
};

/** Register user with supabase and return a formatted user object */
export const registerSupabase = async (
  formValues: RegisterFormType,
  isAnonymous: boolean | undefined
) => {
  const {
    data: { user },
    error,
  } = await supabase.auth.signUp({
    email: formValues.email,
    password: formValues.password,
    options: {
      data: {
        display_name: formValues.name,
        teamId: isAnonymous
          ? sessionStorage.getItem(SESSION_STORAGE.ANON_USER_TEAM_ID)
          : undefined, // add team id for converting anonymous user
      },
      emailRedirectTo: `${window.location.origin}${ROUTES.SIGN_IN_PAGE}`,
    },
  });

  if (error) throw error;
  if (user && user.identities && user.identities.length === 0)
    throw new AuthApiError(
      AUTH_ERROR_MESSAGES.EMAIL.ALREADY_EXIST,
      409,
      "Conflict"
    );
};

/** Sign in user and return a formatted user object */
export const signInSupabase = async (formValues: SignInFormType) => {
  const {
    data: { user },
    error,
  } = await supabase.auth.signInWithPassword({
    email: formValues.email,
    password: formValues.password,
  });

  if (error) throw error;

  return formatUser(user);
};

/** Refreshes the user session and return a formatted user object */
export const refreshSessionSupabase = async () => {
  const {
    data: { user },
    error,
  } = await supabase.auth.refreshSession();
  return { user: formatUser(user), error };
};

/** sign out user */
export const signOutSupabase = async (options?: {
  scope: "global" | "local" | "others";
}) => {
  const { scope = "local" } = options || {};

  const { error } = await supabase.auth.signOut({ scope });
  if (error) throw error;
};

/** Sends and email to the user that redirects to the reset password page */
export const forgotPasswordSupabase = async ({
  email,
}: forgotPasswordFormType) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${window.location.origin}${ROUTES.RESET_PASSWORD_PAGE}`,
  });
  if (error) throw error;
};

/** Update the password and signs out the user from all other sessions */
export const resetPasswordSupabase = async ({
  password,
}: ResetPasswordPasswordFormType) => {
  const { error } = await supabase.auth.updateUser({
    password,
  });
  if (error) throw error;

  signOutSupabase({ scope: "others" });
};

/** Add the team ID to the user meta data in supabase and return a formatted user object */
export const addTeamIdSupabase = async ({ team_id }: TeamDetailsType) => {
  const {
    data: { user },
    error: getUserError,
  } = await supabase.auth.getUser();
  if (getUserError) throw getUserError;

  const { error } = await supabase.auth.updateUser({
    data: { ...user?.user_metadata, teamId: team_id },
  });
  if (error) throw error;

  const {
    data: { user: updatedUser },
    error: getUpdatedUserUserError,
  } = await supabase.auth.getUser();
  if (getUpdatedUserUserError) throw getUpdatedUserUserError;

  return formatUser(updatedUser);
};

export const signInAnonymousUser = async () => {
  const {
    data: { user },
    error,
  } = await supabase.auth.signInAnonymously();
  if (error) throw error;
  return formatUser(user);
};

export const signInUserWithProvider = async (
  provider: Provider,
  redirectTo?: string
) => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider,
    options: {
      redirectTo:
        redirectTo || `${window.location.origin}${ROUTES.TEAM_ID_PAGE}`,
    },
  });

  if (error) {
    throw error;
  }
};

export const addTeamIdForConvertedOAuthUserByOAuth = async (user: IUser) => {
  // Check if user is converted by OAuth
  const isConverted =
    sessionStorage.getItem(SESSION_STORAGE.IS_CONVERTED_ANON_USER_BY_OAuth) ===
    "true";

  const teamId = sessionStorage.getItem(SESSION_STORAGE.ANON_USER_TEAM_ID);
  // Add team id for converting anonymous user
  if (isConverted && teamId && user && !user.teamId) {
    return await addTeamIdSupabase({
      team_id: teamId,
    });
  }

  return user;
};
