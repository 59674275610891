export interface miniLeagueURLParams {
  teamId: string | number;
  leagueId: string | number;
  history?: boolean;
  gw: string | number;
  phase: number | string;
  pageNumber: number | string;
}

export const FPL_CHAMP_ENDPOINTS = {
  CONTACT_US: `/marketing/contact-us`,
  VALIDATE_TEAM_ID: (team_id: string | number) =>
    `/teams/validate-team-id?team_id=${team_id}`,
  GET_LEAGUES: (teamId: string) => `/teams/${teamId}/leagues`,
  GET_LEAGUE_INFO: (leagueId: string | number, isShared?: boolean) =>
    `/teams/league-info/${leagueId}?${isShared ? "share=true" : ""}`,

  HIGHLIGHTS_URL: (
    gameWeek: number | string,
    leagueId: number | string,
    teamId: string | number,
    type?: "slow" | "fast",
    isShared?: boolean
  ) =>
    `/highlights${
      type && `/${type}`
    }?gw=${gameWeek}&league=${leagueId}&teamId=${teamId}${
      isShared ? "&share=true" : ""
    }`,
  HIGHLIGHTS_INFO: (isShared?: boolean) =>
    `/highlights/info?${isShared ? "share=true" : ""}`,
  GLOBAL_INFO: (isShared?: boolean) =>
    `/highlights/global-info?${isShared ? "share=true" : ""}`,
  CATEGORIES: (isShared?: boolean) =>
    `/highlights/categories?${isShared ? "share=true" : ""}`,
  SUBSCRIBE_TO_EMAIL_MARKETING: `/marketing/subscribe-to-email-marketing`,
  GET_TEAM_ID_BY_TEAM_NAME: (teamName: string, pageNumber: number) =>
    `/teams/get-team-id-by-team-name?teamName=${teamName}&pageNumber=${pageNumber}`,
  TEAM_DATA_URL: (teamId: string | number, gameWeek: string | number) =>
    `/teams/${teamId}?gw=${gameWeek}`,
  EPL_PLAYERS_SUMMARY: (
    teamId: string | number,
    gameWeek: string | number,
    leagueId: string | number
  ) => `/teams/${teamId}/ownership?gw=${gameWeek}&league=${leagueId}`,
  EPL_PLAYERS_OWNED_SINCE: (
    eplPlayerId: string | number,
    fplManagers: string,
    gameWeek: string | number
  ) =>
    `/teams/owned-since?gw=${gameWeek}&eplPlayerId=${eplPlayerId}&fplManagers=${fplManagers}`,
  MINI_LEAGUE: ({
    leagueId,
    gw,
    teamId,
    phase = 1,
    pageNumber = 1,
    history = false,
  }: miniLeagueURLParams) =>
    `/leagues/${leagueId}/standings?gw=${gw}&teamId=${teamId}&phase=${phase}&pageNumber=${pageNumber}&history=${history}`,

  MINI_LEAGUE_CAPTAINS: (
    leagueId: string | number,
    gw: string | number,
    teamId: string | number
  ) => `/leagues/${leagueId}/captains?gw=${gw}&teamId=${teamId}`,

  MINI_LEAGUE_MOST_HURTFUL: (
    leagueId: string | number,
    gw: string | number,
    managerId: string | number
  ) => `/leagues/${leagueId}/hurtful-players?gw=${gw}&entry=${managerId}`,

  MINI_LEAGUE_NEW_ENTRIES: (
    leagueId: string | number,
    pageNumber: string | number
  ) => `/leagues/${leagueId}/new-entries?pageNumber=${pageNumber}`,

  FIXTURES: (
    leagueId: string | number,
    teamId: string | number,
    gw: string | number,
    fgw: string | number
  ) => `/fixtures?entry=${teamId}&league=${leagueId}&gw=${gw}&fgw=${fgw}`,

  PLAYER_OWNERSHIP_FIXTURE: (
    leagueId: string | number,
    fixtureId: string | number,
    teamId: string | number,
    gw: string | number,
    fgw: string | number
  ) =>
    `/fixtures/${fixtureId}?league=${leagueId}&teamId=${teamId}&gw=${gw}&fgw=${fgw}`,

  GET_MANGER_TRANSFERS_URL: (managerId: string | number) =>
    `/transfers/manager/${managerId}`,

  GET_LEAGUE_TRANSFERS_URL: (
    leagueId: string | number,
    gw: string | number,
    pageNumber: number | string,
    teamId?: string | number,
    isWidget?: boolean
  ) =>
    `/transfers/leagues/${leagueId}?gw=${gw}&pageNumber=${pageNumber}${
      teamId ? `&teamId=${teamId}` : ""
    }${isWidget ? `&isWidget=${isWidget}` : ""}`,

  ANALYTICS: () => `/marketing/analytics`,
  TEAM_ID_ANALYTICS: () => `/marketing/analytics/team-id-page`,
} as const;
