export const CONTACT_US_PAGE_CONTENT = {
  META: {
    TITLE: "Contact us",
  },
  TITLE: "Contact Us",
  SUBTITLE:
    "Get in touch with FPLChamp team for any inquires, partnerships, suggestions or help.",
  EMAIL_FIELD_PLACEHOLDER: "Email",
  MESSAGE_FILED_PLACEHOLDER: "Enter message here",
  TITLE_FILED_PLACEHOLDER: "Message title",
  CTA_BUTTON_TEXT: "Submit",
  SUCCESS:
    "Thank you for contacting us, keep an eye on your email and we will reply to you as soon as possible.",
};

export const CONTACT_US_ERROR_MESSAGES = {
  MESSAGE: {
    MIN: "Message must be at least 30 Charters",
    MAX: "Message must be less than 2000  Charters",
    REQUIRED: "Message field Required",
  },
  TITLE: {
    REQUIRED: "Tile field required",
  },
};
