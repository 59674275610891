import { useEffect } from "react";

const useDisableScroll = (
  isDisabled: boolean,
  scrollableRef?: React.RefObject<HTMLElement>
) => {
  const keys: { [key: string]: number } = { 37: 1, 38: 1, 39: 1, 40: 1 }; // arrow keys

  const preventDefault = (e: Event) => {
    e.preventDefault();
  };

  const preventDefaultForScrollKeys = (e: KeyboardEvent) => {
    if (keys[e.code]) {
      preventDefault(e);
      return false;
    }
  };

  const shouldPreventDefault = (e: Event) => {
    if (scrollableRef?.current) {
      const target = e.target as HTMLElement;
      if (scrollableRef.current.contains(target)) {
        return false; // Allow scrolling within the scrollable element
      }
    }
    return true; // Prevent scrolling elsewhere
  };

  useEffect(() => {
    // Check for passive event listener support
    let supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        null as any,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    const wheelOpt = supportsPassive ? { passive: false } : false;
    const wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    const handleWheel = (e: Event) => {
      if (shouldPreventDefault(e)) {
        preventDefault(e);
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (shouldPreventDefault(e)) {
        preventDefault(e);
      }
    };

    const disableScroll = () => {
      window.addEventListener("DOMMouseScroll", handleWheel, false); // older Firefox
      window.addEventListener(wheelEvent, handleWheel, wheelOpt); // modern desktop
      window.addEventListener("touchmove", handleTouchMove, wheelOpt); // mobile
      window.addEventListener("keydown", preventDefaultForScrollKeys, false);
    };

    const enableScroll = () => {
      window.removeEventListener("DOMMouseScroll", handleWheel, false);
      window.removeEventListener(wheelEvent, handleWheel, wheelOpt as any);
      window.removeEventListener("touchmove", handleTouchMove, wheelOpt as any);
      window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
    };

    if (isDisabled) {
      disableScroll();
    } else {
      enableScroll();
    }

    // Cleanup on unmount
    return () => enableScroll();
  }, [isDisabled, scrollableRef]);

  useEffect(() => {
    // return () => enableScroll();
  }, []);
};

export default useDisableScroll;
