import { Navigate } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { useGetUrl } from "./useGetUrl";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";

/**
 * Placeholder until home page is implement
 * A Navigate component that redirect the user from "/" to "/league/:leagueId/highlights?..."
 * @returns Navigate Component to Highlights
 */

export const NavigateToHomePage = () => {
  const { user } = useUserContext();
  const { leagues } = useGlobalInfoContext();
  const { getHomePath } = useGetUrl();

  if (user && leagues.length) {
    return (
      <Navigate
        to={getHomePath(
          user.favoriteLeagueId || user.selectedLeagueId || leagues[0].id
        )}
        replace
      />
    );
  }
};
