import Rollbar from "rollbar";
import { ENV_OPTIONS } from "./app.config";

const isProd = import.meta.env.VITE_ENVIRONMENT === ENV_OPTIONS.PROD;
const codeVersion = import.meta.env.VITE_CODE_VERSION; // defined as env var in vite.config.js

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  captureUncaught: isProd,
  captureUnhandledRejections: isProd,
  enabled: isProd,
  codeVersion: codeVersion,
  addErrorContext: true,
  version: codeVersion,
  payload: {
    client: {
      javascript: {
        guess_uncaught_frames: true,
        source_map_enabled: true,
        code_version: codeVersion,
      },
    },
  },
};

export const rollbar = new Rollbar(rollbarConfig);
