import { css } from "@emotion/react";
import { IconButton, Modal, Slide, Stack, Typography } from "@mui/material";
import shareIcon from "../assets/icons/share.svg";
import { useEffect, useState } from "react";
import { mq, transition_all } from "../config/styles";
import whatsappIcon from "../assets/icons/whatsapp.svg";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import { PATH_PARAMS, QUERY_PARAMS } from "../config/routes";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../utils/google-analytics";
import { useParams } from "react-router-dom";
import { useUserContext } from "../context/UserContext";

const shareButtons = [
  {
    icon: whatsappIcon,
    link: "https://api.whatsapp.com/send?text=",
  },
];

/** Renders the share button that opens up a modal to share or copy link as in the highlights page */
export const AppShareButton = (props: { message: string }) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { user } = useUserContext();

  const sharedURL =
    location.href +
    `&${QUERY_PARAMS.SHARED_USER_TEAM_ID}=${user!.teamId}&${
      QUERY_PARAMS.SHARED
    }=true`;

  const params = useParams();

  const handleClose = () => {
    setIsShareOpen(false);
  };

  const handleOpen = () => {
    setIsShareOpen(true);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(sharedURL);
    setIsCopied(true);
    collectEvent();
  };

  useEffect(() => {
    let timeOut: ReturnType<typeof setTimeout>;

    if (isCopied) {
      timeOut = setTimeout(() => {
        setIsCopied(false);
      }, 1500); // display "Copied" for 1.5s and then return to "Copy Link"
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [isCopied]);

  const collectEvent = () => {
    const highlightId = params[PATH_PARAMS.HIGHLIGHT_ID] || "all-highlights";
    TrackGoogleAnalyticsEvent(GA4_EVENTS.SHARE_HIGHLIGHTS_PAGE(highlightId));
  };

  return (
    <>
      <IconButton disabled={!user} css={shareBtnStyles} onClick={handleOpen}>
        <img src={shareIcon} />
      </IconButton>

      <Modal open={isShareOpen} onClose={handleClose}>
        <Slide direction="up" in={isShareOpen} mountOnEnter unmountOnExit>
          <Stack css={modalContainerStyles}>
            <Typography css={titleStyles}>Share</Typography>

            <Stack css={iconsLayout}>
              {shareButtons.map((item) => (
                <Stack key={item.link} css={shareIconContainer}>
                  <IconButton
                    onClick={collectEvent}
                    data-action="share/whatsapp/share"
                    LinkComponent={"a"}
                    href={`${item.link}${
                      props.message
                    }%0A%0A${encodeURIComponent(sharedURL)}`}
                    rel="nofollow noopener"
                    target="_blank"
                  >
                    <img src={item.icon} />
                  </IconButton>
                  WhatsApp
                </Stack>
              ))}

              <Stack css={shareIconContainer}>
                <IconButton onClick={copyLink}>
                  <InsertLinkOutlinedIcon sx={{ color: "black" }} />
                </IconButton>
                {isCopied ? "Copied" : "Copy Link"}
              </Stack>
            </Stack>
          </Stack>
        </Slide>
      </Modal>
    </>
  );
};

const shareBtnStyles = css`
  ${transition_all}
  background-color: white;
  padding: 0.3rem;
  border-radius: 0.3rem;

  :hover {
    background-color: #e1e1e1;
  }

  :disabled {
    opacity: 0.5;
  }

  img {
    width: clamp(1rem, 3vw, 1.5rem);
  }
`;

const modalContainerStyles = css`
  color: black;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  position: absolute;
  left: 0;
  width: calc(100% - 2rem);
  margin: 1rem;
  height: 10rem;
  bottom: 1rem;

  ${mq["sm"]} {
    left: 50%;
    translate: -50%;
    max-width: 600px;
  }
`;

const titleStyles = css`
  font-size: 1.2rem;
  font-weight: 600;
`;

const iconsLayout = css`
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * {
    flex: 1;
  }
`;

const shareIconContainer = css`
  justify-content: center;
  align-items: center;
`;
