import { css, SerializedStyles } from "@emotion/react";
import { Stack, IconButton } from "@mui/material";
import { transition_all } from "../config/styles";
import { bgColor } from "../config/theme";
import crown from "../assets/landing-page/crown.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const CTACard = ({
  show,
  onCloseBtnClick,
  children,
  extraStyles,
}: {
  show: boolean;
  onCloseBtnClick: () => void;
  children: React.ReactNode;
  extraStyles?: SerializedStyles;
}) => {
  return (
    <Stack
      css={[
        exploreHighlightsContainerStyles,
        exploreHighlightsShowStyles(show),
        extraStyles,
      ]}
    >
      <IconButton css={closeButtonStyles} onClick={onCloseBtnClick}>
        <CloseRoundedIcon />
      </IconButton>
      <Stack css={exploreHighlightsStyles}>
        <img src={crown} css={exploreHighlightsCrownImageStyles("top-left")} />
        <img
          src={crown}
          css={exploreHighlightsCrownImageStyles("bottom-left")}
        />
        <img
          src={crown}
          css={exploreHighlightsCrownImageStyles("bottom-right")}
        />
        <img src={crown} css={exploreHighlightsCrownImageStyles("top-right")} />
        {children}
      </Stack>
    </Stack>
  );
};

const exploreHighlightsShowStyles = (show: boolean) => css`
  ${transition_all}

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  ${show &&
  css`
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  `}
`;

const exploreHighlightsContainerStyles = css`
  overflow: hidden;
  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

const exploreHighlightsStyles = css`
  position: relative;
  isolation: isolate;
  overflow: hidden;
  background-color: ${bgColor}cc;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
`;

const exploreHighlightsCrownImageStyles = (
  position: "top-left" | "top-right" | "bottom-left" | "bottom-right"
) => css`
  position: absolute;
  z-index: 1;
  opacity: 0.2;
  --left-right-position: -0.5rem;
  --x-position: 0;

  ${position === "top-left" &&
  css`
    top: 0;
    left: var(--left-right-position);
    translate: -20% 0;
    transform: rotateZ(45deg);
  `}

  ${position === "top-right" &&
  css`
    top: 0;
    right: var(--left-right-position);
    translate: 20% 0;
    transform: rotateZ(-35deg);
  `}
  
    ${position === "bottom-left" &&
  css`
    bottom: 0;
    left: var(--left-right-position);
    translate: -20% 0;
    transform: rotateZ(-35deg);
  `}
  
    ${position === "bottom-right" &&
  css`
    bottom: 0;
    right: var(--left-right-position);
    translate: 20% 0;
    transform: rotateZ(35deg);
  `}
`;

const closeButtonStyles = css`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 3;
  color: white;
  background-color: #2a292954;
`;
