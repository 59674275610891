import { Form, Formik } from "formik";
import { Button, ErrorMessage, Input, TextField } from "../../../components";
import { root } from "../../auth-pages/styles";
import { CONTACT_US_PAGE_CONTENT } from "../../../constants/contact-us-page.constants";
import message from "../../../assets/icons/message.svg";
import { handleErrors } from "../../../utils/handleErrors";
import { ContactFormType, contactUsYupSchema } from "../../../utils/validation";
import { axiosInstance } from "../../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../../config/fplChampEndpoints.constants";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { useEffect } from "react";

/**
 * Form for contact us page that sends a POST request to the server
 * with the message details.
 *
 * @returns Form component
 */

export const ContactUsForm = () => {
  const initialValues = {
    email: "",
    title: "",
    message: "",
    generalErr: "",
  };

  const { initialSnackBarState, setSnackBarState } = useSnackbarContext();

  const submitForm = async (
    values: ContactFormType,
    { setSubmitting, setErrors, resetForm }: any
  ) => {
    try {
      setSubmitting(true);
      // throws error
      await axiosInstance.post(FPL_CHAMP_ENDPOINTS.CONTACT_US, {
        email: values.email,
        title: values.title,
        message: values.message,
      });

      resetForm();

      setSnackBarState((prev) => ({
        ...prev,
        message: CONTACT_US_PAGE_CONTENT.SUCCESS,
        isOpen: true,
      }));
    } catch (e) {
      const err = handleErrors(e);
      setErrors({ generalErr: err.message });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    // close snakeBar on leave/unMount
    return setSnackBarState(initialSnackBarState);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactUsYupSchema}
      onSubmit={submitForm}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting, setFieldValue, setFieldError }) => (
        <Form css={root(isSubmitting)}>
          <Input
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
              setFieldError(e.target.name, "");
            }}
            Icon={<img src={message} />}
            placeholder={CONTACT_US_PAGE_CONTENT.EMAIL_FIELD_PLACEHOLDER}
            id="email"
            name="email"
          />

          <Input
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
              setFieldError(e.target.name, "");
            }}
            placeholder={CONTACT_US_PAGE_CONTENT.TITLE_FILED_PLACEHOLDER}
            id="title"
            name="title"
          />

          <TextField
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
              setFieldError(e.target.name, "");
            }}
            name="message"
            id="message"
            placeholder={CONTACT_US_PAGE_CONTENT.MESSAGE_FILED_PLACEHOLDER}
            limit={2000}
          />

          <ErrorMessage name="generalErr" />
          <Button button="CTA" type="submit" disabled={isSubmitting}>
            {CONTACT_US_PAGE_CONTENT.CTA_BUTTON_TEXT}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
