import { IconButton, Modal, Stack } from "@mui/material";
import { css } from "@emotion/react";
import { tableHeaderBgColor } from "../config/theme";
import whiteRadialGradient from "../assets/common/white-radial-gradient.png";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useDisableScroll from "../utils/hooks/useDisableScroll";
import { useRef } from "react";

interface IAppModal {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  showGradientBg?: boolean;
}

export const AppModal = (props: IAppModal) => {
  const ref = useRef(null);
  useDisableScroll(props.open, ref);

  return (
    <Modal
      ref={ref}
      css={modalContainer}
      open={props.open}
      onClose={props.onClose}
    >
      <Stack css={rootStyles}>
        {props.showCloseButton && (
          <IconButton css={iconBtnStyles} onClick={props.onClose}>
            <CloseRoundedIcon />
          </IconButton>
        )}
        {props.showGradientBg && (
          <img
            css={radialGradientStyles}
            src={whiteRadialGradient}
            alt="white radial gradient"
          />
        )}
        {props.children}
      </Stack>
    </Modal>
  );
};

const modalContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100vw;
  height: 100vh;
`;

const rootStyles = css`
  position: relative;
  isolation: isolate;
  gap: 1rem;
  background-color: ${tableHeaderBgColor};
  width: 100%;
  max-width: 500px;
  border-radius: 1rem;
  padding-block: 1rem;
  max-height: 80vh;
  max-height: 90svh;
  overflow: auto !important;
`;

const radialGradientStyles = css`
  width: 50%;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
`;

const iconBtnStyles = css`
  position: absolute;
  top: 1rem;
  right: 1rem;

  svg {
    color: aliceblue;
  }
`;
