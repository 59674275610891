import { axiosInstance } from "../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../config/fplChampEndpoints.constants";

export const postAnalytics = async ({
  analyticsType,
  email,
}: {
  analyticsType: string;
  email?: string;
}) => {
  return await axiosInstance.post(FPL_CHAMP_ENDPOINTS.ANALYTICS(), {
    analyticsType: analyticsType,
    email: email,
  });
};

export const postTeamIdAnalytics = async (body: {
  email: string;
  teamId?: string;
  searchTerm?: string;
  inputType?: "byName" | "byId";
}) => {
  return await axiosInstance.post(
    FPL_CHAMP_ENDPOINTS.TEAM_ID_ANALYTICS(),
    body
  );
};
